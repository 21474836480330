/* eslint-disable nordic/no-img-tags */
/* eslint-disable react/jsx-wrap-multilines */
const React = require('react');
const { getEventTracker } = require('../../../../../utils/event-tracker');
const { Background } = require('@andes-landings/background');
const { Image } = require('@andes/common');
const { Hero } = require('@andes-landings/hero');
const { useI18n } = require('nordic/i18n');


const HomeHero = ({ brand, language, assetsPath }) => {
  const { i18n } = useI18n();
  const Tracker = getEventTracker();

  const getImageName = () => {
    const getDailyHash = () => {
      const today = new Date()?.toISOString()?.split('T')[0];
      let hash;

      try {
        const storedData = localStorage.getItem('dailyHash');

        if (storedData) {
          const { date, hash: storedHash } = JSON.parse(storedData);

          if (date === today) {
            hash = storedHash;
          } else {
            hash = Date.now().toString(16);
            localStorage.setItem('dailyHash', JSON.stringify({ date: today, hash }));
          }
        } else {
          hash = Date.now().toString(16);
          localStorage.setItem('dailyHash', JSON.stringify({ date: today, hash }));
        }
      } catch (error) {
        console.error('An error occurred:', error);
        hash = Date.now().toString(16);
      }

      return hash;
    };

    const hash = getDailyHash();

    if (brand === 'yellowblue-light') {
      return language === 'pt' ? `imgHeroHomePT_YB.png?${hash}` : `imgHeroHomeES_YB.png?${hash}`;
    }
    return language === 'pt' ? `imgHeroHomePT.png?${hash}` : `imgHeroHomeES.png?${hash}`;
  };

  const getImageSrc = () => {
    const imageName = getImageName();
    return `${assetsPath}images/${imageName}`;
  };

  return (
    <Background
      className="home-hero"
      component="section"
      curves={3}
      type="hero"
    >
      <div className="home-hero-container">
        <Hero
          actions={{
            primary: {
              children: i18n.gettext('Explorar integraciones'),
              onClick: () => {
                Tracker.send('mpdevelopers/click_home_hero_button');
                window.location.href = `/developers/${language}/docs`;
              },
            },
          }}
          className={`${brand !== 'yellowblue-light' ? 'home-hero-white-text' : ''}`}
          img={
            <Image
              alt={i18n.gettext('DEVELOPERS')}
              src={getImageSrc()}
            />
          }
          title={{
            description:
              i18n.gettext('Ofrecemos soluciones inteligentes, ventajas competitivas y una comunidad para acompañarte.'),
            title: i18n.gettext('Simplifica tus cobros con integraciones a medida'),
            volanta: i18n.gettext('DEVELOPERS'),
          }}
        />
      </div>
    </Background>
  );
};


module.exports = HomeHero;
